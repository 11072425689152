import React, {useEffect, useState} from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isInteger from 'lodash/isInteger';
import last from 'lodash/last';
import {getClass, isManufacturerListing, toHTMLId} from './commonHelper';
import {formatNumber, formatPrice, formatWithDecimal} from '@dmm/lib-common/lib/formatting';
import he from 'he';
import {getConfig} from '../config/portal';
import {
  getCapacity,
  getCapacitySymbol,
  getCurrency,
  getCurrencyFormat,
  getCurrencySymbol,
  getDistance,
  getDistanceSymbol, getGreatWeightSymbol,
  getLength as getLengthUom,
  getLengthSymbol,
  getLocale,
  getSpeed,
  getSpeedSymbol,
  getWeight,
  getWeightSymbol
} from './uomHelper';
import {getCurrentCurrency, getCurrentLocale} from './language';
import {SHOW_BOAT_NAME_MIN_LENGTH} from '../constants/index';
import {TITLE_DATA, TITLE_FORMAT} from '../constants/boatDetails/index';
import {getPartyBrandedSRP} from '../utils/urlHelpers/party';
import {asArray, definedValue} from './index';
import {generateSearchPath} from './urlHelpers/boats';
import {imageUrlWithDimensions} from './urlHelpers/boat';

import {getBoatsConstants, TAG_ATTRIBUTES} from '../constants/boats';
import {ImageWrapper} from '@dmm/lib-react-ui-components';
import {prepareImageSrc} from '../components/CarouselItem/helpers';
import JWPlayer from '../components/JWPlayer';
import Image360 from '../components/CarouselItem/Image360';
import defaultImage from '../icons/placeholder-image.svg';
import VisibilitySensor from 'react-visibility-sensor';
import {syncTranslationElements} from '../tppServices/translations/intlManager';
import {getBoatLoanConstants} from '../constants/BoatLoans';
import {calculateMonthlyPrice} from './trident';

export const getValueTranslation = (group, value) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const normalizedValue = toHTMLId(value || '');
  if (messages.boatDetails.value[group][normalizedValue]) {
    return t(messages.boatDetails.value[group][normalizedValue]);
  }
  return he.decode(value || '');
};

export const getFriendlyFeatureName = (key) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  if (messages.boatDetails.friendlyFeatureName[key]) {
    return t(messages.boatDetails.friendlyFeatureName[key]);
  }
  return he.decode(key || '');
};

const shouldDisplayYear = (listing) =>
  Number.isInteger(listing.year) &&
  (!listing.isOemModel || listing.year <= new Date().getFullYear() + 1);

export const getTitle = (
  listing,
  showBoatName = false,
  h1Format = TITLE_FORMAT,
  locale = undefined,
  customUom = undefined
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let title = '';
  const boatLengthMeters = get(
    listing,
    'specifications.dimensions.lengths.nominal.m',
    0
  );
  let boatName =
    boatLengthMeters >= SHOW_BOAT_NAME_MIN_LENGTH && showBoatName
      ? get(listing, 'boatName', '')
      : '';
  boatName = boatName ? ' ' + boatName : boatName;
  const length = getLength(listing, locale, customUom, true);
  const className =
    listing.class && messages.classFacetValues[listing.class]
      ? t(messages.classFacetValues[listing.class])
      : '';
  if (listing.id) {
    title = h1Format;
    title = title.replace(
      '{year}',
      shouldDisplayYear(listing) ? listing.year : ''
    );
    title = title.replace('{make}', listing.make);
    title = title.replace('{model}', get(listing, 'model', ''));
    // NOTE: This is weird, I know. But the function literally returns "undefined" when the lenght can't be properly built.
    title = length === 'undefined' ? title : title.replace('{length}', length);
    title = className ? title.replace('{class}', className) : title;
    title = title.replace('{power}', get(getEnginePower(listing), 'value', ''));
    return (title + boatName).trim();
  }
  return title;
};

export const getBoatAge = (listing) => {
  return isInteger(listing.year) ? new Date().getFullYear() - listing.year : 0;
};

export const getTitleData = (
  listing,
  locale,
  customUom,
  titleData = TITLE_DATA
) => {
  const length = getLength(listing, locale, customUom, true);
  return titleData.map((element) => {
    switch (element) {
      case 'length':
        return {
          facet: 'length',
          data: length
        };
      case 'year':
        return {
          facet: 'year',
          data: listing.year
        };
      default:
        return {};
    }
  });
};

const _extractElectronics = (data) => {
  let electronics = [];
  data.map((electronic) => {
    electronics.push({
      title: getFriendlyFeatureName(electronic.code),
      value: electronic.value ? electronic.value : '✓'
    });
  });
  return electronics;
};

const _extractEquiptments = (data) => {
  let equipements = [];
  data.map((equipement) => {
    equipements.push({
      title: getFriendlyFeatureName(equipement.code),
      value: equipement.value ? equipement.value : '✓'
    });
  });
  return equipements;
};

const _extractRigging = (data) => {
  let riggings = [];
  data.map((rigging) => {
    riggings.push({
      title: getFriendlyFeatureName(rigging.code),
      value: rigging.value ? rigging.value : '✓'
    });
  });
  return riggings;
};

const _extractSails = (data) => {
  let sails = [];
  data.map((sail) => {
    sails.push({
      title: getFriendlyFeatureName(sail.code),
      value: sail.value ? sail.value : '✓'
    });
  });
  return sails;
};

export const getFeaturesData = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  if (listing.id && listing.features) {
    let features = [];
    let electronicsData = get(listing, 'features.electronics');
    let electricalEquipmentsData = get(listing, 'features.electricalEquipment');
    let insideEquipmentsData = get(listing, 'features.insideEquipment');
    let outsideEquipmentsData = get(listing, 'features.outsideEquipmentExtras');
    let additionalEquipmentsData = get(listing, 'features.additionalEquipment');
    let riggingData = get(listing, 'features.rigging');
    let sailsData = get(listing, 'features.sails');
    if (electricalEquipmentsData) {
      const electricalEquipments = _extractElectronics(
        electricalEquipmentsData
      );
      features.push({
        title: t(messages.boatDetails.features['electrical-equipment']),
        value: electricalEquipments
      });
    }

    if (electronicsData) {
      const electronics = _extractElectronics(electronicsData);
      features.push({
        title: t(messages.boatDetails.features.electronics),
        value: electronics
      });
    }

    if (insideEquipmentsData) {
      const insideEquipments = _extractEquiptments(insideEquipmentsData);
      features.push({
        title: t(messages.boatDetails.features['inside-equipment']),
        value: insideEquipments
      });
    }

    if (outsideEquipmentsData) {
      const outsideEquipments = _extractEquiptments(outsideEquipmentsData);
      features.push({
        title: t(messages.boatDetails.features['outside-equipment']),
        value: outsideEquipments
      });
    }

    if (additionalEquipmentsData) {
      const additionalEquipment = _extractEquiptments(additionalEquipmentsData);
      features.push({
        title: t(messages.boatDetails.features['additional-equipment']),
        value: additionalEquipment
      });
    }

    if (riggingData) {
      const rigging = _extractRigging(riggingData);
      features.push({
        title: t(messages.boatDetails.features.rigging),
        value: rigging
      });
    }

    if (sailsData) {
      const sails = _extractSails(sailsData);
      features.push({
        title: t(messages.boatDetails.features.sails),
        value: sails
      });
    }
    return features;
  }
  return [];
};

export const getPrice = (listing, customOptions) => {
  customOptions = !customOptions ? getCurrentCurrency() : customOptions;
  const { formatMessage: t, messages } = syncTranslationElements();
  if (customOptions) {
    const shouldShowOriginalCurrency =
      get(getConfig(), 'supports.showOriginalCurrency', false) &&
      get(listing, 'price.enteredCurrency') !==
        get(customOptions, 'currency.code') &&
      !get(listing, 'price.hidden');
    const price = !get(listing, 'price.hidden')
      ? formatPrice(
          get(
            listing,
            `price.type.amount.${get(
              customOptions,
              'currency.code',
              getCurrency()
            )}`
          ),
          get(customOptions, 'currency.code', getCurrency()),
          getLocale(),
          customOptions
        )
      : t(messages.pricing.request);
    if (shouldShowOriginalCurrency) {
      const customFormattingOnly = cloneDeep(customOptions);
      delete customFormattingOnly.currency;
      const originalPrice = formatPrice(
        get(
          listing,
          `price.type.amount.${get(listing, 'price.enteredCurrency')}`
        ),
        get(listing, 'price.enteredCurrency'),
        getLocale(),
        customFormattingOnly
      );
      return `${originalPrice} (${price})`;
    }
    return price;
  }
  return !get(listing, 'price.hidden')
    ? formatPrice(
        get(listing, `price.type.amount.${getCurrency()}`),
        getCurrency(),
        getLocale()
      )
    : t(messages.pricing.request);
};

export const getPriceV2 = (listing, customOptions, myCurrency) => {
  customOptions = !customOptions
    ? getCurrentCurrency(myCurrency)
    : customOptions;
  const { formatMessage: t, messages } = syncTranslationElements();
  if (customOptions) {
    const shouldShowOriginalCurrency =
      get(getConfig(), 'supports.showOriginalCurrency', false) &&
      !!get(listing, 'price.enteredCurrency') &&
      get(listing, 'price.enteredCurrency') !==
        get(customOptions, 'currency.code') &&
      !get(listing, 'price.hidden');
    const price = !get(listing, 'price.hidden')
      ? formatPrice(
          get(
            listing,
            `price.type.amount.${get(
              customOptions,
              'currency.code',
              getCurrency()
            )}`
          ),
          get(customOptions, 'currency.code', getCurrency()),
          getLocale(),
          customOptions
        )
      : t(messages.pricing.request);
    if (shouldShowOriginalCurrency) {
      const customFormattingOnly = cloneDeep(customOptions);
      delete customFormattingOnly.currency;
      const originalPrice = formatPrice(
        get(
          listing,
          `price.type.amount.${get(listing, 'price.enteredCurrency')}`
        ),
        get(listing, 'price.enteredCurrency'),
        getLocale(),
        customFormattingOnly
      );
      return `${originalPrice} (${price})`;
    }
    return price;
  }
  return !get(listing, 'price.hidden')
    ? formatPrice(
        get(listing, `price.type.amount.${getCurrency()}`),
        getCurrency(),
        getLocale()
      )
    : t(messages.pricing.request);
};

export const getLength = (listing, locale, customUom, roundValue = false) => {
  const uom = customUom ? customUom.abbr : getLengthUom();
  const symbol = customUom ? customUom.symbol : getLengthSymbol();
  const length = roundValue
    ? Math.round(
        get(listing, `specifications.dimensions.lengths.nominal.${uom}`)
      )
    : get(listing, `specifications.dimensions.lengths.nominal.${uom}`);
  return (
    formatWithDecimal(length, locale ? locale : getLocale(), 2, null, true) +
    symbol
  );
};

export const floorLength = (length) => {
  if (length > 999) {
    return 999;
  }
  return Math.floor(length);
};

export const getHullParam = (value) => {
  return value.split('-').join(' ');
};

export const getLocation = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const { city, subdivision, country } = get(listing, 'location.address', {});
  const fullSubdivision =
    messages.countrySubdivision[country] &&
    messages.countrySubdivision[country][subdivision]
      ? t(messages.countrySubdivision[country][subdivision])
      : subdivision;

  const fullCountry = messages.countries[country]
    ? t(messages.countries[country])
    : country;
  if (city) {
    return city + ', ' + (fullSubdivision || fullCountry);
  }
  if (subdivision) {
    return fullSubdivision;
  }
  return fullCountry;
};

export const getSubdivision = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const { country, subdivision } = get(listing, 'location.address', {});
  const fullSubdivision =
    messages.countrySubdivision[country] &&
    messages.countrySubdivision[country][subdivision]
      ? t(messages.countrySubdivision[country][subdivision])
      : subdivision;

  if (subdivision) {
    return fullSubdivision;
  }
};

export const getWarrantyLabel = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const warranty = get(listing, 'legal.hullWarranty');
  if (!warranty) {
    return;
  }
  const isMonth = last(warranty) === 'm';
  if (isMonth) {
    return warranty.replace('m', ` ${t(messages.boatDetails.warranty.months)}`);
  }
  const years = Number(warranty);
  if (years === 1) {
    return t(messages.boatDetails.warranty.oneYear, { years });
  }
  if (years === 99) {
    return t(messages.boatDetails.warranty.lifetime);
  }
  if (years > 0) {
    return t(messages.boatDetails.warranty.years, { years });
  }
};

export const getDetailsData = (listing, { length: customLength } = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let details = [];
  if (shouldDisplayYear(listing)) {
    details.push({
      title: t(messages.boatDetails.details.year),
      value: listing.year
    });
  }
  if (listing.make) {
    details.push({
      title: t(messages.boatDetails.details.make),
      value: listing.make
    });
  }
  if (listing.model) {
    details.push({
      title: t(messages.boatDetails.details.model),
      value: listing.model
    });
  }
  if (listing.class) {
    details.push({
      title: t(messages.boatDetails.details.class),
      value: t(messages.classFacetValues[listing.class])
    });
  }
  if (
    listing.specifications &&
    get(
      listing,
      `specifications.dimensions.lengths.nominal.${getLengthUom(customLength)}`
    )
  ) {
    let length = getLength(listing, undefined, customLength);
    details.push({
      title: t(messages.boatDetails.details.length),
      value: length
    });
  }
  if (listing.fuelType) {
    details.push({
      title: t(messages.boatDetails.details['fuel-type']),
      value: getValueTranslation('fuelType', getHullParam(listing.fuelType))
    });
  }
  if (listing.hull) {
    if (listing.hull.material) {
      details.push({
        title: t(messages.boatDetails.details['hull-material']),
        value: getValueTranslation(
          'hullMaterial',
          getHullParam(listing.hull.material)
        )
      });
    }
    if (listing.hull.shape) {
      details.push({
        title: t(messages.boatDetails.details['hull-shape']),
        value: getValueTranslation(
          'hullShape',
          getHullParam(listing.hull.shape)
        )
      });
    }
    if (
      listing.hull.hin &&
      get(getConfig(), 'supports.hideHin', false) !== true
    ) {
      details.push({
        title: t(messages.boatDetails.details.hin),
        value: listing.hull.hin
      });
    }
  }
  const warrantyLabel = getWarrantyLabel(listing);
  if (warrantyLabel) {
    details.push({
      title: t(messages.boatDetails.details['hull-warranty']),
      value: warrantyLabel
    });
  }
  if (listing.owner) {
    details.push({
      title: t(messages.contactGroup.offeredBy),
      value: isFSBOContact(listing.contact) ? t(messages.fsbo) : getSanitisedDealerName(listing.owner)
    });
  }
  return details;
};

export const getRelatedRange = (value, rangeList) => {
  if (rangeList === undefined || rangeList.length === 0) {
    return {};
  }

  rangeList.sort((a, b) => a.min - b.min);

  const foundRange = rangeList.find((range) => {
    const { min, max } = range;
    const isInRange = value >= min && value < max;
    if (isInRange) {
      return true;
    }
    return false;
  });

  if (foundRange) {
    return foundRange;
  }

  const { max } = rangeList[rangeList.length - 1];
  return { min: max };
};

export const formatRange = (range) => {
  if (range.max === undefined) {
    return `${range.min}+`;
  }
  return `${range.min} - ${range.max}`;
};

const _getDefaultLengthRanges = () => [
  {
    min: 0,
    max: 8
  },
  {
    min: 8,
    max: 10
  },
  {
    min: 10,
    max: 12
  },
  {
    min: 12,
    max: 15
  }
];

const _getDefaultYearRange = () => ({
  low: 5,
  high: 5
});

const _getDefaultPriceRanges = () => [
  {
    min: 0,
    max: 5000
  },
  {
    min: 5000,
    max: 10000
  },
  {
    min: 10000,
    max: 20000
  },
  {
    min: 20000,
    max: 30000
  },
  {
    min: 30000,
    max: 50000
  },
  {
    min: 30000,
    max: 50000
  },
  {
    min: 50000,
    max: 100000
  },
  {
    min: 100000,
    max: 300000
  }
];

export const getSavedSearchDetailsData = (
  listing,
  { length: customLength } = {}
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let details = [];
  const lengthUom = getLengthUom(customLength);

  if (listing.class) {
    details.push({
      title: t(messages.boatDetails.details.class),
      value: t(messages.classFacetValues[listing.class])
    });
  }

  if (
    listing.specifications &&
    get(listing, `specifications.dimensions.lengths.nominal.${lengthUom}`)
  ) {
    const length = Number(
      getLength(listing, undefined, customLength).replace(lengthUom, '')
    );
    const lengthRanges = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedLengthRanges`,
      _getDefaultLengthRanges()
    );
    const relatedLengthRange = getRelatedRange(length, lengthRanges);
    details.push({
      title: t(messages.boatDetails.details.length),
      value: `${formatRange(relatedLengthRange)} ${lengthUom}`
    });
  }

  if (listing.year) {
    const yearRange = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedYearRanges`,
      _getDefaultYearRange()
    );
    details.push({
      title: t(messages.boatDetails.details.year),
      value: `${listing.year - yearRange.low} - ${
        listing.year + yearRange.high
      }`
    });
  }

  if (listing.price) {
    const priceRanges = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedPriceRanges`,
      _getDefaultPriceRanges()
    );
    const price = get(
      listing,
      `price.type.amount.${get(listing, 'price.enteredCurrency')}`
    );
    const relatedPriceRange = getRelatedRange(price, priceRanges);
    const currencyFormat =
      getCurrencyFormat() === 'postfix' ? 'suffix' : getCurrencyFormat();
    const priceFormatted =
      currencyFormat === 'suffix'
        ? `${formatRange(relatedPriceRange)} ${getCurrencySymbol()}`
        : `${getCurrencySymbol()} ${formatRange(relatedPriceRange)}`;
    details.push({ title: t(messages.price), value: priceFormatted });
  }

  return details;
};

export const getSavedSearchDetailsSearchPath = (
  listing,
  { length: customLength } = {}
) => {
  let relatedPriceRange;
  let relatedLengthRange;
  let relatedYearRange;
  let relatedMultiFacetedBoatTypeClass;

  const lengthUom = getLengthUom(customLength);

  if (listing.price) {
    const priceRanges = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedPriceRanges`,
      _getDefaultPriceRanges()
    );
    const price = get(
      listing,
      `price.type.amount.${get(listing, 'price.enteredCurrency')}`
    );
    relatedPriceRange = getRelatedRange(price, priceRanges);
  }

  if (
    listing.specifications &&
    get(listing, `specifications.dimensions.lengths.nominal.${lengthUom}`)
  ) {
    const length = Number(
      getLength(listing, undefined, customLength).replace(lengthUom, '')
    );
    const lengthRanges = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedLengthRanges`,
      _getDefaultLengthRanges()
    );

    relatedLengthRange = getRelatedRange(length, lengthRanges);
  }

  if (listing.year) {
    const yearRange = get(
      getConfig(),
      `languages.${getCurrentLocale()}.relatedYearRanges`,
      _getDefaultYearRange()
    );
    relatedYearRange = {
      min: listing.year - yearRange.low,
      max: listing.year + yearRange.high
    };
  }

  if (listing.type && listing.class) {
    relatedMultiFacetedBoatTypeClass = {
      [listing.type]: [listing.class]
    };
  }

  const searchPath = generateSearchPath({
    price: relatedPriceRange,
    length: relatedLengthRange,
    year: relatedYearRange,
    multiFacetedBoatTypeClass: relatedMultiFacetedBoatTypeClass
  });
  return searchPath;
};

export const getPropulsionData = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let details = [];
  if (listing.propulsion && !isEmpty(listing.propulsion.engines)) {
    if (listing.propulsion.engines.length > 1) {
      details = listing.propulsion.engines.map((engine, index) => {
        return {
          title: t(messages.boatDetails.propulsion.engine, {
            number: index + 1
          }),
          value: _extractPropulsionData(engine)
        };
      });
    } else {
      details = _extractPropulsionData(listing.propulsion.engines[0]);
    }
  }
  return details;
};

export const getEnginePower = (engine) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let engineDetails = {
    title: '',
    value: ''
  };
  if (get(engine, 'power.hp')) {
    engineDetails = {
      title: t(messages.boatDetails.propulsion['total-power']),
      value: t(messages.boatDetails.propulsion.power.hp, {
        power: engine.power.hp
      })
    };
  } else if (get(engine, 'power.kW')) {
    engineDetails = {
      title: t(messages.boatDetails.propulsion['total-power']),
      value: t(messages.boatDetails.propulsion.power.kW, {
        power: engine.power.kW
      })
    };
  }
  return engineDetails;
};

const _extractPropulsionData = (engine) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let engineDetails = [];
  if (engine.make) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['engine-make']),
      value: `${engine.make}`
    });
  }
  if (engine.model) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['engine-model']),
      value: `${engine.model}`
    });
  }
  if (engine.year) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['engine-year']),
      value: `${engine.year}`
    });
  }
  if (engine.power) {
    if (engine.power.hp) {
      engineDetails.push({
        title: t(messages.boatDetails.propulsion['total-power']),
        value: t(messages.boatDetails.propulsion.power.hp, {
          power: engine.power.hp
        })
      });
    } else if (engine.power.kW) {
      engineDetails.push({
        title: t(messages.boatDetails.propulsion['total-power']),
        value: t(messages.boatDetails.propulsion.power.kW, {
          power: engine.power.kW
        })
      });
    }
  }
  if (engine.hours) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['engine-hours']),
      value: `${engine.hours}`
    });
  }
  if (engine.category) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['engine-type']),
      value: getValueTranslation('engineType', engine.category)
    });
  }
  if (engine.driveType) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['drive-type']),
      value: getValueTranslation('driveType', engine.driveType)
    });
  }
  if (engine.location) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion.location),
      value: getValueTranslation('engineLocation', engine.location)
    });
  }
  if (engine.fuel) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['fuel-type']),
      value: getValueTranslation('fuelType', engine.fuel)
    });
  }
  if (engine.ropeCutter) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['rope-cutter']),
      value: true
    });
  }
  if (engine.propellerType) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['propeller-type']),
      value: getValueTranslation('propellerType', engine.propellerType)
    });
  }
  if (engine.propellerMaterial) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['propeller-material']),
      value: getValueTranslation('propellerMaterial', engine.propellerMaterial)
    });
  }
  if (engine.foldingPropeller) {
    engineDetails.push({
      title: t(messages.boatDetails.propulsion['folding-propeller']),
      value: true
    });
  }
  return engineDetails;
};

const _buildFieldTitle = (property) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const translationKey = get(messages.boatDetails.measurements, property);
  if (translationKey) {
    return t(translationKey);
  }
  const upper = property.charAt(0).toUpperCase();
  const buildedTitle = upper + property.slice(1);
  return buildedTitle;
};

// The following function should be considered as a temporary function to iterate over an object and get all
// existing fields. Why? Because there are many translations that do not exist at the moment,
// so this will change the way we handle details, descriptions and measurements when those translations
// are added
const _getAvailableFields = (section) => {
  let listOfAvailableProperties = [];
  let fieldTitle;
  let sectionObject = { ...section };
  delete sectionObject.weight;
  for (const property in sectionObject) {
    fieldTitle = _buildFieldTitle(property).replace(/([a-z])([A-Z])/g, '$1 $2');
    if (
      typeof sectionObject[property] === 'object' &&
      sectionObject[property] !== null
    ) {
      const unit = Object.keys(sectionObject[property])[0];
      const value = Object.values(sectionObject[property])[0];
      const applicableValue = `${value}${unit}`;
      listOfAvailableProperties.push({
        title: fieldTitle,
        value: applicableValue
      });
    } else if (sectionObject[property]) {
      const applicableValue = sectionObject[property];
      listOfAvailableProperties.push({
        title: fieldTitle,
        value: applicableValue
      });
    }
  }
  return listOfAvailableProperties;
};

export const getEngineDetailsData = (engine) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let engineDetailsData = [];

  if (engine.make) {
    engineDetailsData.push({
      title: t(messages.boatDetails.details.make),
      value: engine.make
    });
  }
  if (engine.model) {
    engineDetailsData.push({
      title: t(messages.boatDetails.details.model),
      value: engine.model
    });
  }
  if (engine.year) {
    engineDetailsData.push({
      title: t(messages.boatDetails.details.year),
      value: engine.year
    });
  }
  if (engine.power) {
    if (engine.power.hp) {
      engineDetailsData.push({
        title: t(messages.boatDetails.propulsion['total-power']),
        value: t(messages.boatDetails.propulsion.power.hp, {
          power: engine.power.hp
        })
      });
    } else if (engine.power.kW) {
      engineDetailsData.push({
        title: t(messages.boatDetails.propulsion['total-power']),
        value: t(messages.boatDetails.propulsion.power.kW, {
          power: engine.power.kW
        })
      });
    }
  }
  let address = get(engine, 'location.address');
  if (address) {
    const location = getLocation(engine);
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.location),
      value: location
    });
  }
  if (engine.hours) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion['engine-hours']),
      value: engine.hours
    });
  }
  if (engine.fuel) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion['fuel-type']),
      value: t(messages.boatDetails.value.fuelType[engine.fuel])
    });
  }
  if (engine.condition) {
    engineDetailsData.push({
      title: t(messages.condition),
      value: t(messages[engine.condition])
    });
  }
  // Translations needed
  if (engine.configuration) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.configuration),
      value: getValueTranslation('configuration', engine.configuration)
    });
  }
  if (engine.use) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.use),
      value: getValueTranslation('use', engine.use)
    });
  }
  if (engine.numberCylinders) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.numberCylinders),
      value: engine.numberCylinders
    });
  }
  if (engine.stockNumber) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.stockNumber),
      value: engine.stockNumber
    });
  }
  if (engine.serialNumber) {
    engineDetailsData.push({
      title: t(messages.boatDetails.propulsion.serialNumber),
      value: engine.serialNumber
    });
  }

  return engineDetailsData;
};

export const getEngineMeasurementsData = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let details = [];

  const measurements = get(listing, 'measurements', {});
  details = _getAvailableFields(measurements);
  if (measurements.weight) {
    let weight = _extractWeights(measurements.weight);
    details.push({
      title: t(messages.boatDetails.measurements.weights),
      value: weight.value
    });
  }
  return details;
};

export const getMeasurementsData = (listing, customUom = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let details = [];
  const specs = get(listing, 'specifications', {});
  const equipment = get(listing, 'features.equipment', {});

  if (specs.speedDistance) {
    let speed = _extractSpeedAndDistanceData(specs.speedDistance, customUom);
    if (speed.length !== 0) {
      details.push({
        title: t(messages.boatDetails.measurements['speed-distance']),
        value: speed
      });
    }
  }
  if (specs.dimensions) {
    let dim = _extractDimensions(specs.dimensions, customUom);
    if (dim.length !== 0) {
      details.push({
        title: t(messages.boatDetails.measurements.dimensions),
        value: dim
      });
    }
  }
  if (specs.weights) {
    let weights = _extractWeights(specs.weights, customUom);
    if (weights.length !== 0) {
      details.push({
        title: t(messages.boatDetails.measurements.weights),
        value: weights
      });
    }
  }
  let misc = _extractMiscDimensions(specs, equipment);
  if (misc.length !== 0) {
    details.push({
      title: t(messages.boatDetails.measurements.miscellaneous),
      value: misc
    });
  }

  if (listing.tanks) {
    let tanks = _extractTanks(listing.tanks, customUom);
    if (tanks.length !== 0) {
      details.push({
        title: t(messages.boatDetails.measurements.tanks),
        value: tanks
      });
    }
  }

  if (specs.accommodation) {
    let accom = _extractAccomodations(specs.accommodation);
    if (accom.length !== 0) {
      details.push({
        title: t(messages.boatDetails.measurements.accommodations),
        value: accom
      });
    }
  }
  return details;
};

const _extractSpeedAndDistanceData = (data, customUom) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const customSpeed = get(customUom, 'speed');
  const customDistance = get(customUom, 'distance');
  let speedSymbol = customSpeed ? customSpeed.symbol : getSpeedSymbol();
  let speedAbbr = customSpeed ? customSpeed.abbr : getSpeed();

  let speed = [];
  if (data.cruisingSpeed) {
    speed.push({
      title: t(messages.boatDetails.measurements['cruising-speed']),
      value: data.cruisingSpeed[speedAbbr] + speedSymbol
    });
  }
  if (data.maxHullSpeed) {
    speed.push({
      title: t(messages.boatDetails.measurements['max-speed']),
      value: data.maxHullSpeed[speedAbbr] + speedSymbol
    });
  }
  if (data.range) {
    const distanceSymbol = customDistance
      ? customDistance.symbol
      : getDistanceSymbol();
    const distanceAbbr = customDistance ? customDistance.abbr : getDistance();
    speed.push({
      title: t(messages.boatDetails.measurements.range),
      value: data.range[distanceAbbr] + distanceSymbol
    });
  }
  return speed;
};

const _extractDimensions = (data, customUom) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const customLengthUom = get(customUom, 'length');
  const lengthSymbol = customLengthUom
    ? customLengthUom.symbol
    : getLengthSymbol();
  const lengthMetric = customLengthUom ? customLengthUom.abbr : getLengthUom();
  let dimensions = [];
  const overall = get(data, `lengths.overall.${lengthMetric}`);
  const deck = get(data, `lengths.deck.${lengthMetric}`);
  const waterline = get(data, 'lengths.waterline');
  if (overall) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['length-overall']),
      value: overall + lengthSymbol
    });
  }
  if (deck) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['length-on-deck']),
      value: deck + lengthSymbol
    });
  }
  if (data.maxBridgeClearance) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['max-bridge-clearance']),
      value: data.maxBridgeClearance[lengthMetric] + lengthSymbol
    });
  }
  if (data.maxDraft) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['max-draft']),
      value: data.maxDraft[lengthMetric] + lengthSymbol
    });
  }
  if (data.freeboard) {
    dimensions.push({
      title: t(messages.boatDetails.measurements.freeboard),
      value: data.freeboard[lengthMetric] + lengthSymbol
    });
  }
  if (data.beam) {
    dimensions.push({
      title: t(messages.boatDetails.measurements.beam),
      value: data.beam[lengthMetric] + lengthSymbol
    });
  }
  if (data.cabinHeadroom) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['cabin-headroom']),
      value: data.cabinHeadroom[lengthMetric] + lengthSymbol
    });
  }
  if (waterline) {
    dimensions.push({
      title: t(messages.boatDetails.measurements['length-at-waterline']),
      value: waterline[lengthMetric] + lengthSymbol
    });
  }
  return dimensions;
};

const _extractWeights = (weights, { weight: customWeight } = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const weightSymbol = customWeight ? customWeight.symbol : getWeightSymbol();
  const weightMetric = customWeight ? customWeight.abbr : getWeight();
  let weight = [];
  if (weights.dry || weights.type === 'dry') {
    const amount = weights.dry
      ? weights.dry[weightMetric]
      : weights[weightMetric];
    let formattedDry = formatNumber(amount, getLocale());
    weights.type === 'dry'
      ? (weight = {
          title: t(messages.boatDetails.measurements['dry-weight']),
          value: formattedDry + weightSymbol
        })
      : weight.push({
          title: t(messages.boatDetails.measurements['dry-weight']),
          value: formattedDry + weightSymbol
        });
  }
  if (getConfig()?.supports?.enableNewSpecifications) {
    const greatWeightSymbol = getGreatWeightSymbol();
    weights.grossTonnage ? weight.push({
      title: t(messages.boatDetails.measurements['gross-tonnage']),
      value: weights.grossTonnage + greatWeightSymbol
    }) : null;
  }
  return weight;
};

const _extractMiscDimensions = (specs, equipment) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let miscellaneous = [];

  const seats = get(specs, 'capacity.seatingCapacity');
  const windlass = get(equipment, 'windlass');
  const liferaft = get(specs, 'capacity.liferaftCapacity');
  const deadrise = get(specs, 'dimensions.deadriseAtTransom');
  const circuit = get(equipment, 'electricalCircuit');
  if (seats) {
    miscellaneous.push({
      title: t(messages.boatDetails.measurements['seating-capacity']),
      value: seats
    });
  }
  if (windlass) {
    miscellaneous.push({
      title: t(messages.boatDetails.measurements.windlass),
      value: getValueTranslation('windlass', windlass)
    });
  }
  if (liferaft) {
    miscellaneous.push({
      title: t(messages.boatDetails.measurements['liferaft-capacity']),
      value: liferaft
    });
  }
  if (deadrise) {
    miscellaneous.push({
      title: t(messages.boatDetails.measurements['deadrise-at-transom']),
      value: `${deadrise.value}${deadrise.unit}`
    });
  }
  if (circuit) {
    miscellaneous.push({
      title: t(messages.boatDetails.measurements['electrical-circuit']),
      value: getValueTranslation('electricalCircuit', circuit)
    });
  }
  return miscellaneous;
};

export const formatMoreDetailsEngineDescriptions = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let moreDetails = [];

  if (listing.rpmMax) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.rpmMax),
      value: `${listing.rpmMax}rpm`
    });
  }
  if (listing.fuelInductionSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.fuelInductionSystem),
      value: listing.fuelInductionSystem
    });
  }
  if (listing.startingSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.startingSystem),
      value: listing.startingSystem
    });
  }
  if (listing.ignitionSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.ignitionSystem),
      value: listing.ignitionSystem
    });
  }
  if (listing.lubricationSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.lubricationSystem),
      value: listing.lubricationSystem
    });
  }
  if (listing.steeringSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.steeringSystem),
      value: listing.steeringSystem
    });
  }
  if (listing.coolingSystem) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.coolingSystem),
      value: listing.coolingSystem
    });
  }
  if (listing.emissions) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion.emissions),
      value: listing.emissions
    });
  }
  if (listing.propellerType) {
    moreDetails.push({
      title: t(messages.boatDetails.propulsion['propeller-type']),
      value: listing.propellerType
    });
  }
  return moreDetails;
};

export const formatMoreDetailsDescriptions = (descriptions, currentLanguage) => {
  if (descriptions && descriptions.length > 0) {
    const languageTranslationsEnabled = Object.keys(descriptions[0]).some( key => key === 'language');
    return descriptions
      .filter((desc) => {
        const isPublic = desc.visibility === 'public';
        if (languageTranslationsEnabled) {
          return isPublic && desc.language === currentLanguage;
        }
        return isPublic;
      })
      .map((description) => {
        return {
          title: getValueTranslation('moreDetailsSection', description.title),
          value: description.description
        };
      });
  }
};

const getLanguageFromLocale = (locale) => {
  return locale?.split('-')[0];
};

export const formatMainDescription = (description, mainDescriptions = [], selectedLanguage) => {
  const locale = get(
    getConfig(),
    'locale',
    undefined
  );
  const portalDefaultLocale = getLanguageFromLocale(locale);
  const selectedDescription = mainDescriptions.find((description) => description.language === selectedLanguage);
  if (selectedDescription) {
    return selectedDescription.description;
  }
  const portalDefaultDescription = mainDescriptions.find((description) => description.language === portalDefaultLocale);
  if (portalDefaultDescription) {
    return portalDefaultDescription.description;
  }

  const defaultDescription = mainDescriptions.find((description) => !description.language);
  if (defaultDescription) {
    return defaultDescription.description;
  }
  return description;
};

const _extractTanks = (tank, customUom) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const customCapacity = get(customUom, 'capacity');
  let tanks = [];

  const freshWaterTanks = get(tank, 'freshWater');
  const fuelTanks = get(tank, 'fuel');
  const holdingTanks = get(tank, 'holding');

  const getTanksValue = (tanks) =>
    tanks
      .map((tank) => {
        const capacityUnit = customCapacity
          ? customCapacity.abbr
          : getCapacity();
        const capacitySymbol = customCapacity
          ? customCapacity.symbol
          : getCapacitySymbol();
        const capacity = get(tank, `capacity[${capacityUnit}]`)
          ? `${tank.capacity[capacityUnit]} ${capacitySymbol} `
          : '';
        const material = getValueTranslation(
          'tankMaterial',
          get(tank, 'tankMaterial', '')
        );
        const quantity = get(tank, 'quantity') ? `${tank.quantity} x ` : '';
        const theMaterial = material ? `(${material})` : '';
        return `${quantity}${capacity}${theMaterial}`;
      })
      .join(', ');

  if (freshWaterTanks) {
    tanks.push({
      title: t(messages.boatDetails.measurements['fresh-water-tank']),
      value: getTanksValue(freshWaterTanks)
    });
  }
  if (fuelTanks) {
    tanks.push({
      title: t(messages.boatDetails.measurements['fuel-tank']),
      value: getTanksValue(fuelTanks)
    });
  }
  if (holdingTanks) {
    tanks.push({
      title: t(messages.boatDetails.measurements['holding-tank']),
      value: getTanksValue(holdingTanks)
    });
  }

  return tanks;
};

const _extractAccomodations = (accommodation) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let accommodations = [];
  if (getConfig()?.supports?.enableNewSpecifications) {
    accommodation.guestCabins && accommodations.push({
      title: t(messages.boatDetails.measurements['guest-cabins']),
      value: accommodation.guestCabins
    });

    accommodation.guestHeads && accommodations.push({
      title: t(messages.boatDetails.measurements['guest-heads']),
      value: accommodation.guestHeads
    });

    accommodation.crewCabins && accommodations.push({
      title: t(messages.boatDetails.measurements['crew-cabins']),
      value: accommodation.crewCabins
    });

    accommodation.crewHeads && accommodations.push({
      title: t(messages.boatDetails.measurements['crew-heads']),
      value: accommodation.crewHeads
    });
  } else {
    accommodation.singleBerths && accommodations.push({
      title: t(messages.boatDetails.measurements['single-berths']),
      value: accommodation.singleBerths
    });

    accommodation.doubleBerths && accommodations.push({
      title: t(messages.boatDetails.measurements['double-berths']),
      value: accommodation.doubleBerths
    });

    accommodation.twinBerths && accommodations.push({
      title: t(messages.boatDetails.measurements['twin-berths']),
      value: accommodation.twinBerths
    });

    accommodation.cabins && accommodations.push({
      title: t(messages.boatDetails.measurements.cabins),
      value: accommodation.cabins
    });

    accommodation.heads && accommodations.push({
      title: t(messages.boatDetails.measurements.heads),
      value: accommodation.heads
    });

  }

  return accommodations;
};

export const getPrePopulatedText = (listing) => {
  return `I'm interested in getting more information about your ${getTitle(
    listing
  )}. Please contact me.`;
};

export const isFSBOContact = (contact) => {
  return get(contact, 'name') === 'Private Seller';
};

export const getSanitisedDealerName = (owner) => {
  let dealerName = '';
  let name = owner.name;
  if (owner.rootName && !name.includes(owner.rootName)) {
    dealerName = owner.rootName + ' - ';
  }
  dealerName += name;
  return dealerName;
};

const _stripQueryParams = (url) => {
  return url.split('?')[0];
};

export const addResizeParams = (
  url,
  resize,
  modified,
  exact = true,
  format = ''
) => {
  if (!url) {
    return url;
  }

  if (!resize?.width) {
    return url;
  }

  let timestamp = modified ? new Date(modified).getTime() : null;
  return (
    _stripQueryParams(url) +
    `?w=${resize.width}&h=${resize.height}${
      timestamp ? `&t=${timestamp}` : ''
    }${exact ? '&exact' : ''}${format ? '&format=' + format : ''}`
  );
};

export const addNotModifiedResizeParams = (url, resize, exact = true, format = 'webp') => {
  if (!url) {
    return url;
  }
  if (!resize?.width) {
    return url;
  }
  if (!resize?.height) {
    return  _stripQueryParams(url) + `?w=${resize.width}&format=${format}${exact ? '&exact' : ''}`;
  }

  return  _stripQueryParams(url) + `?w=${resize.width}&h=${resize.height}&format=${format}${exact ? '&exact' : ''}`;
};

export const getMediaImages = (listing) => {
  const listingMedia = get(listing, 'media', []);
  return listingMedia.filter((item) => {
    return item.mediaType === 'image';
  });
};

export const get360Photos = (listing) => {
  const listingMedia = get(listing, 'media', []);
  return listingMedia.filter((item) => {
    return item.mediaType === '360Image';
  });
};

export const getMediaVideos = (listing) => {
  const listingMedia = get(listing, 'media', []);
  return listingMedia.filter((item) => {
    return _isYouTubeVideo(item) || item.mediaType === 'video';
  });
};
export const populateMediaArray = (listing, isStackedGallery) => {
  let images = getMediaImages(listing);
  let videos = getMediaVideos(listing);
  let images360 = get360Photos(listing);
  if (images.length > 0 || videos.length > 0 || images360.length > 0) {
    if (images.length > 0) {
      if (isStackedGallery) {
        return [images[0], ...videos, ...images360, ...images.slice(1)];
      }
      return [images[0], ...videos, ...images360, images.length - 1];
    }
    return [...videos, ...images360];
  }
  return [];
};

const _isYouTubeVideo = (item) => {
  if (item.mediaType === 'external') {
    let videoUrl = get(item, 'videoEmbedUrl') || '';
    var regExp =
      /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/;
    return videoUrl.match(regExp);
  }
};

export const getSellerName = (sellerContact = {}, preferredName = '') => {
  const { formatMessage: t, messages } = syncTranslationElements();
  if (isFSBOContact(sellerContact)) {
    return t(messages.fsbo);
  }

  return preferredName || sellerContact.rootName || sellerContact.name || '';
};

export const isAvailableForLiveVideoTour = (listing) =>
  get(listing, 'attributes', []).includes('LIVE_VIDEO_TOUR');

export const getBrokerSpotlightInfo = (
  party,
  spotlightDetails,
  openContactFormFunc,
  openListingsFunc,
  mode
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  if (!spotlightDetails || !party) {
    return null;
  }
  const salesRep = spotlightDetails.details
    ? {
        normalizedName: spotlightDetails.details.normalizedBrokerName,
        id: spotlightDetails.id
      }
    : null;

  const brokerageLogoUrl = setImageUrl({
    imageUrl: spotlightDetails?.details?.brokerageLogoUrl,
    resizerImageParams: {
      width: LOGO_SIZES.brokerCardLogo.w,
      height: LOGO_SIZES.brokerCardLogo.h
    }
  });

  const spotlightInfo = {
    brokerCardMode: mode,
    brokerImageUrl: get(spotlightDetails, 'details.brokerImageUrl', null),
    brokerageLogoUrl: brokerageLogoUrl,
    brokerageUrl: `${getPartyBrandedSRP(party, false, null)}`,
    brokerName: get(spotlightDetails, 'details.brokerName', ''),
    parentName: get(spotlightDetails, 'details.parentName', ''),
    contactButtonText: t(messages.contactGroup.contact),
    listingsButtonText: t(messages.brokerListings),
    brokerListingsUrl: `${getPartyBrandedSRP(party, false, null, salesRep)}`,
    brokerListings: [],
    contactButtonClick: openContactFormFunc,
    listingsButtonClick: openListingsFunc,
    listingsButtonToHref: true,
    premiumBroker: null //this will need to be changed once the premiumBroker info exists.
  };
  return spotlightInfo;
};

export const currencyFormattingOptions = (currentLocale) => {
  let formatingOptions = get(
    getConfig(),
    `languages.${currentLocale}.currency.format`,
    undefined
  );
  const currencyCode = get(
    getConfig(),
    `languages.${currentLocale}.currency.abbr`,
    null
  );
  const currency = get(
    getConfig(),
    `supportedCurrencies.${currencyCode}`,
    undefined
  );
  return { 'currency': currency, 'formatingOptions': formatingOptions };
};

export const featuredBoatsProps = (featuredListings, intl) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let listings = asArray(featuredListings);
  const currentLocale = get(intl, 'locale', 'us');
  const customPriceOptions = currencyFormattingOptions(currentLocale);
  const featuredLabel = t(messages.featured);
  return listings.map((fb) => {
    const {
      provisioningName,
      title,
      logoUrl,
      listingUrl,
      externalUrl,
      photoUrl,
      location,
      imtId
    } = fb;
    // If we get an externalUrl we use it. It is the way it currently works and might change in the future.
    const featuredLink = externalUrl || listingUrl;
    return {
      provisioningName,
      title,
      logoUrl,
      listingUrl: featuredLink,
      externalLink: !!externalUrl,
      photoUrl,
      id: imtId,
      location,
      featuredLabel,
      price: getPrice(fb, customPriceOptions)
    };
  });
};

export const canPrequalify = (listing) => {
  const price = getPrice(listing);
  const boatAge = getBoatAge(listing);
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const { MIN_PRICE_TO_PREQUALIFY, MAX_AGE_TO_PREQUALIFY } = boatLoansConstants;

  if (price === 'Request price') {
    return false;
  }

  const priceAsNumber = Number(price.replace(/[^0-9.-]+/g, ''));
  return !(
    priceAsNumber < MIN_PRICE_TO_PREQUALIFY || boatAge > MAX_AGE_TO_PREQUALIFY
  );
};

export const listingAttribute = (listing) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const attributes = get(listing, 'attributes', []);
  if (attributes.length === 0) {
    return {
      hasAttribute: false,
      attributeText: '',
      attributeItem: ''
    };
  }
  const attributeItem = attributes.find((a) => TAG_ATTRIBUTES.includes(a));
  const hasAttributeItem = !!attributeItem;
  return {
    hasAttribute: hasAttributeItem,
    attributeText: hasAttributeItem && t(messages[attributeItem]),
    attributeItem
  };
};

// This is a workaround to avoid SSR errors when using Image in the server
export const safeWindow = () => (typeof window !== 'undefined' ? window : {});
/* istanbul ignore next */
const csrUrl = (url) => (Object.keys(safeWindow()).length === 0 ? null : url);

export const useLoadedImage = (url) => {
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (csrUrl(url)) {
      const img = new Image();
      img.onerror = () => setImageUrl(null);
      img.onload = () => setImageUrl(url);
      img.src = url;
    }
  }, []);
  return imageUrl;
};

export const getListingType = (listing) => {
  const { LISTING_MANUFACTURER, LISTING_ENHANCED, LISTING_STANDARD } =
    getBoatsConstants();

  return isManufacturerListing(listing.isOEM)
    ? LISTING_MANUFACTURER
    : get(listing, 'featureType.enhanced', false)
      ? LISTING_ENHANCED
      : LISTING_STANDARD;
};

export const getBoatDetails = (listing, { length: customLength } = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const engineData =
    listing?.propulsion?.engines && listing.propulsion.engines.length > 0
      ? listing.propulsion.engines[0]
      : null;
  const capacity = listing?.specifications?.capacity?.seatingCapacity;
  const engineName =
    engineData?.make && engineData?.model
      ? `${engineData.make} ${engineData.model}`
      : engineData?.make || engineData?.model;
  const length =
    listing?.specifications?.dimensions?.lengths?.nominal?.[
      getLengthUom(customLength)
    ] && getLength(listing, undefined, customLength);

  let totalPowerHp = 0;
  let totalPowerKw = 0;
  const engineHours = listing?.propulsion?.engines?.length ? listing.propulsion.engines[0].hours : 0;
  const engines = listing?.propulsion?.engines || [];
  engines.forEach((engine) => {
    if (engine.power?.hp) {
      totalPowerHp += engine.power.hp;
    } else if (engine.power?.kW) {
      totalPowerKw += engine.power.kW;
    }
  });

  let totalPower = 0;
  if (totalPowerHp) {
    totalPower = `${Math.round(totalPowerHp)}hp`;
  } else if (totalPowerKw) {
    totalPower = `${Math.round(totalPowerKw)}kw`;
  }

  return [
    {
      icon: 'engine',
      title: t(messages.boatDetails.details.engine),
      content: engineName
    },
    {
      icon: 'power',
      title: t(messages.boatDetails.propulsion['total-power']),
      content: totalPower || undefined
    },
    {
      icon: 'engineHours',
      title: t(messages.boatDetails.propulsion['engine-hours']),
      content: engineHours || undefined
    },
    {
      icon: 'boatClass',
      title: t(messages.boatDetails.details.class),
      content: getClass(listing?.class)
        ? getClass(listing.class).name
        : undefined
    },
    {
      icon: 'length',
      title: t(messages.boatDetails.details.length),
      content: length
    },
    {
      icon: 'year',
      title: t(messages.boatDetails.details.year),
      content: listing?.year
    },
    {
      icon: 'boatModel',
      title: t(messages.boatDetails.details.model),
      content: listing?.model
    },
    {
      icon: 'capacity',
      title: t(messages.boatDetails.details.capacity),
      content: capacity && `${capacity} people`
    }
  ];
};

export const checkIfPriceOverThan = (listing, thresholdPrice) => {
  return getPriceAmount(listing) > thresholdPrice;
};

export const showPriceCalculation = (listing, context) => {
  const isRemoveMonthlyFinanceEnabled = get(
    context,
    'supports.isRemoveMonthlyFinanceEnabled',
    false
  );

  if (!isRemoveMonthlyFinanceEnabled) {
    return true;
  }

  const boatLoansConstants = getBoatLoanConstants();
  const { MAX_PRICE_TO_FINANCE } = boatLoansConstants;
  return !checkIfPriceOverThan(listing, MAX_PRICE_TO_FINANCE);
};

const prepareMediaItem = (media) => {
  const mediaItems = [];

  const mediaType = get(media, 'mediaType');

  if (mediaType === 'image') {
    const srcSet = [
      prepareImageSrc(media, 300, 222, 300),
      prepareImageSrc(media, 400, 267, 400),
      prepareImageSrc(media, 700, 466, 700),
      prepareImageSrc(media, 969, 646, 969),
      prepareImageSrc(media, 800, 533, 1200),
      prepareImageSrc(media, 1028, 685, 1600)
    ].join(',');

    const sizes = [
      '(max-width: 300px) 300px',
      '(max-width: 400px) 400px',
      '(max-width: 700px) 700px',
      '(max-width: 969px) 969px',
      '(max-width: 1200px) 1040px',
      '1600px'
    ].join(',');

    let dimensions = {};

    if (media.width && media.height) {
      dimensions = {
        width: media.width,
        height: media.height
      };
    }

    mediaItems.push({
      src: media.url,
      srcset: srcSet,
      sizes: sizes,
      alt: media.title,
      mediaType: media.mediaType,
      dimensions: dimensions
    });
  } else if (mediaType === 'video') {
    mediaItems.push({
      title: media.title,
      src: media.url,
      mediaType: media.mediaType
    });
  } else if (mediaType === '360Image') {
    const pannellumId = 'item' + Math.floor(Math.random() * 100);
    const timestamp = Math.floor(Date.now() / 1000);
    mediaItems.push({
      id: pannellumId,
      src: media.url?.replace('LARGE', 'XLARGE') + `?${timestamp}`,
      mediaType: media.mediaType
    });
  } else {
    let videoEmbedUrl = media.videoEmbedUrl ? media.videoEmbedUrl.replace(/\/v\//i, '/embed/') : media.url;
    mediaItems.push({
      src: videoEmbedUrl,
      title: media.title,
      mediaType: media.mediaType
    });
  }

  return mediaItems;
};

export const prepareMediaItemsForStackedGallery = (galleryMediaItems, isMobile) => {
  const height = isMobile ? '300px' : '550px';
  const mediaItems = [];

  if (galleryMediaItems.length > 0) {
    galleryMediaItems.forEach((media, index) => {
      const processedItems = prepareMediaItem(media);
      processedItems.forEach(item => {
        if (item.mediaType === '360Image') {
          mediaItems.push(
            <VisibilitySensor partialVisibility key={item.id}>
              {({ isVisible }) =>
                isVisible ? (
                  <Image360 id={item.id} media={media} style={{ width: '100%', height: height }} draggable={false} mouseZoom={false} />
                ) : (
                  <div style={{ width: '100%', height: height }}></div>
                )
              }
            </VisibilitySensor>
          );
        } else if (item.mediaType === 'video') {
          mediaItems.push(<JWPlayer title={item.title} media={media} key={index} />);
        } else if (item.mediaType === 'image') {
          mediaItems.push(
            <ImageWrapper
              src={item.src}
              srcset={item.srcset}
              sizes={item.sizes}
              alt={item.alt}
              key={index}
              defaultImage={defaultImage}
              fetchPriority={index === 0}
              lazyLoading={index > 0}
              dimensions={{
                width: media.width,
                height: media.height
              }}
            />
          );
        } else {
          mediaItems.push(
            <iframe
              src={item.src}
              style={{ border: 0, width: '100%', height: height }}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              key={index}
            />
          );
        }
      });
    });
  }

  return mediaItems;
};

export const prepareMediaItemsForVerticalImageGallery = (galleryMediaItems) => {
  const mediaItems = [];

  if (galleryMediaItems.length > 0) {
    galleryMediaItems.forEach((media, index) => {
      const processedItems = prepareMediaItem(media, index);
      processedItems.forEach(item => mediaItems.push(item));
    });
  }

  return mediaItems;
};


/**
 * getCarouselImageUrls
 * @param {Array} listing
 * @param {Number} maxNumOfImages
 * @param {Number} minNumOfImages
 * @returns Object
 * @example {
 * images: [{
 *  image: 'https://www.boat.com/image1.jpg?w=400&h=267&t=1713772923136&exact&format=webp',
 *  imageAlt: 'Image 1'
 * }],
 * imageCount: 5
 * }
 */
export const getCarouselImageUrls = (listing, maxNumOfImages = 5, minNumOfImages = 2) => {
  const listingImages = getMediaImages(listing);
  const imageCount = listingImages?.length;

  if (imageCount < minNumOfImages) {
    return {
      images: listingImages,
      imageCount: imageCount
    };
  }

  const limitImages = listingImages.slice(0, maxNumOfImages);
  const images = limitImages.map((image) => {
    return {
      image: image?.url ? imageUrlWithDimensions(image.url, image.date?.modified) : undefined,
      imageAlt: image.title
    };
  });

  return {
    images,
    imageCount
  };
};

export const roundLength = (length) => {
  if (length > 999) {
    return 999;
  }
  return Math.round(length);
};

export const isListingEnhanced = (listing) => {
  return listing?.featureType?.enhanced || listing?.dataType?.match(/enhanced/);
};

export const resolveMonthlyPrice = (listing, teaserRate) => {
  const loanAmount = parseFloat(getPriceAmount(listing));
  const termInMonths = loanAmount >= 50000 ? 240 : 180;
  return calculateMonthlyPrice(teaserRate, termInMonths, loanAmount);
};

export const getListingLength = (listing, config) => {
  const lengthUOM = definedValue(config.uom?.length?.abbr, 'ft');
  const length = listing.nominalLength || listing.specifications?.dimensions?.lengths?.nominal[lengthUOM] || '';
  return length;
};

export const getListingSubdivision = (listing) => {
  return listing.subdivision || listing.location?.address?.subdivision || '';
};

// default USD currency for YW
export const getPriceAmount = (listing, currency = 'USD') => {
  return listing.priceAmount || listing?.price?.type?.amount[currency] || 0;
};


export const isValidImageUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  url = _stripQueryParams(url);
  // URL should start with http or https and end with .jpg, .jpeg, .png, .gif, .svg or .webp. Query string is optional.
  const regex = /^(http|https):\/\/[^ "]+(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.webp)(\?.*)?$/i;
  return regex.test(url);
};

export const LOGO_SIZES = {
  detailsManufacturer: { w: 300, h: 300 },
  brands: { w: 342, h: 252 },
  broker: { w: 260, h: 120 },
  brokerCardLogo: { w: 200, h: 82 },
  branded: { w: 260, h: 136 },
  listing: { w: 155, h: 60 },
};

/**
 * setImageUrlForResizer
 * @description This function is used to prepare the image url for resizer if it matches a valid domain.
 * @param {String} imageUrl
 * @param {String} domain - default is 'boatsgroup.com' but there are also other domains in Boatsgroup Images CDN
 * @returns Object
 */
export const setImageUrlForResizer = (imageUrl, domain = 'boatsgroup.com') => {
  const url = _stripQueryParams(imageUrl);
  const regex = new RegExp(`https:\\/\\/images(\\.qa)?\\.${domain}\\/images|resize\\/?`);
  const isUrlValidForResizer = regex.test(url);
  if (isUrlValidForResizer) {
    const origin = new URL(url).origin;
    const imagePath = url.replace(/^.*\/\/[^/]+/, '').replace('/images', '/resize');
    return {
      isUrlValidForResizer,
      url: `${origin}${imagePath}`
    };
  }
  return {
    isUrlValidForResizer,
    url: imageUrl
  };
};

/**
 * @param {string} imageUrl
 * @param {object} resizerImageParams
 * @param {number} resizerImageParams.width
 * @param {number} resizerImageParams.height
 * @param {boolean} resizerImageParams.exact - default is false
 * @returns {string}
 * @description Returns the imageUrl with the resizer parameters or
 * original imageUrl if it not valid for the resize or
 * empty string if imageUrl is not valid.
 */
export const setImageUrl = ({ imageUrl, resizerImageParams = {}}) => {
  const { exact = false, width, height } = resizerImageParams;
  const isLogoUrlValid = isValidImageUrl(imageUrl);
  if (!isLogoUrlValid) {
    return '';
  }
  if (!resizerImageParams.width || !resizerImageParams.height) {
    return imageUrl;
  }
  const { isUrlValidForResizer, url } = setImageUrlForResizer(_stripQueryParams(imageUrl));
  return isUrlValidForResizer ? addNotModifiedResizeParams(url, { width, height }, exact) : url;
};
