import { formatNumber, formatPriceWithDecimal } from '@dmm/lib-common/lib/formatting';
import { getBoatLoanConstants } from '../../constants/BoatLoans';
import { getConfig } from 'react-pannellum';
import { getPrice, isFSBOContact, roundLength } from '../listingHelper';
import { capitalize, get } from 'lodash';
import { getCurrentLocale } from '../language';

export const calculateMonthlyPrice = (rate, term, loanAmount, formatPrice = false) => {
  if (!loanAmount || !term || (!rate && rate !== 0)) {return;}

  let parsedRate = rate;
  let loanAmountAsNumber = loanAmount;

  if (typeof loanAmountAsNumber !== 'number') {
    loanAmountAsNumber = parseInt(loanAmount.replace(/[$,]/g, ''));

    if (!loanAmountAsNumber) {return;}
  }

  if (typeof parsedRate === 'string') {
    parsedRate = parseFloat(rate);
  }

  const ratePerMonth = parsedRate / 100 / 12;
  const x = Math.pow(1 + ratePerMonth, term);

  let monthlyPayment = (loanAmountAsNumber * ratePerMonth * x) / (x - 1);

  if (parsedRate === 0 && term > 0) {
    monthlyPayment = loanAmountAsNumber / term;
  } else if (!formatPrice){
    monthlyPayment = Math.round((loanAmountAsNumber * ratePerMonth * x) / (x - 1), 2);
  }

  return formatPrice ? formatPriceWithDecimal(monthlyPayment, 'USD', 'en-US', 2) : formatNumber(monthlyPayment);
};

export const isFinanceable = (price, boatYear, maxPrice) => {
  const boatAge = new Date().getFullYear() - (boatYear || 0);
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const { MIN_PRICE_TO_FINANCE, MAX_AGE_TO_FINANCE, MAX_PRICE_TO_FINANCE } = boatLoansConstants;
  const boatPrice = price || 0;

  let priceMaxed = false;
  if (maxPrice){
    priceMaxed = boatPrice > MAX_PRICE_TO_FINANCE;
  }

  return boatPrice >= MIN_PRICE_TO_FINANCE && boatAge <= MAX_AGE_TO_FINANCE && !priceMaxed;
};

export const createBoatInfoParam = (listing) => {

  let boatLength;
  if (listing.specifications && get(listing, 'specifications.dimensions.lengths.nominal.ft')) {
    boatLength = roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'));
  }

  let totalPowerHp = 0;
  let totalPowerKw = 0;
  const engines = listing?.propulsion?.engines || [];
  engines.forEach((engine) => {
    if (engine.power?.hp) {
      totalPowerHp += engine.power.hp;
    } else if (engine.power?.kW) {
      totalPowerKw += engine.power.kW;
    }
  });

  let totalPower = 0;
  if (totalPowerHp) {
    totalPower = Math.round(totalPowerHp);
  } else if (totalPowerKw) {
    totalPower = Math.round(totalPowerKw);
  }

  const engineData = listing?.propulsion?.engines && listing.propulsion.engines.length > 0 ? listing.propulsion.engines[0] : null;
  const engineMake = engineData?.make;

  return encodeURIComponent(JSON.stringify({
    price: get(listing, 'price.type.amount.USD'),
    type: capitalize(get(listing, 'type')),
    year: get(listing, 'year'),
    make: get(listing, 'make'),
    model: get(listing, 'model'),
    imtId: get(listing, 'id'),
    length: boatLength,
    partyId: get(listing, 'owner.id'),
    engineCount: get(listing, 'propulsion.engines.length'),
    engineMake,
    fuelType: get(listing, 'fuelType'),
    totalPower,
    sellerName: get(listing, 'owner.name'),
    sellerEmail: get(listing, 'contact.email'),
    sellerType: isFSBOContact(listing.contact) ? 'FSBO' : 'Broker/Dealer',
    sellerFinanceAdvantage: get(listing, 'owner.isFinanceAdvantage', false),
  }));
};

/**
 * @param {{ isExperiment: boolean, utmCampaign: string, utmContent?: string, listing?: any}} param
 * @returns
*/
export const getApplyNowLink = ({ isVariant, utmCampaign, utmContent, listing }) => {
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const {CTA_PREQUAL_LINK_ROOT, CTA_APPLY_NOW_LINK_ROOT} = boatLoansConstants;
  const baseUrl = isVariant ? CTA_PREQUAL_LINK_ROOT : CTA_APPLY_NOW_LINK_ROOT;
  let boatInfo;

  if (listing) {
    boatInfo = createBoatInfoParam(listing);
  }

  let ctaLink = `${baseUrl}&utm_campaign=${utmCampaign}${utmContent ? `&utm_content=${utmContent}` : ''}${boatInfo ? `&boatInfo=${boatInfo}` : ''}`;

  return ctaLink;
};

export const goToApplyNow = (listing) => {
  const ctaLink = getApplyNowLink({
    isVariant: false,
    utmCampaign: 'BDPcalculatorapply',
    utmContent: 'YWBDPCalc',
    listing
  });
  window.open(ctaLink, '_blank');
};

export const makePrivateSummaryTrackingEvent = (actionLabel, actionType) => {
  return {
    'event': 'link_click',
    'action_type': actionType,
    'action_label': actionLabel,
    'ga4': {
      page: {
        'detailed_page_name': 'boat details',
        'section': 'listings',
        'subsection': 'boat listings'
      }
    }
  };
};

export const isFinanceSegmentCandidate = (listing) =>{
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const isOemModel = listing.isOemModel;
  const selectedLocale = getCurrentLocale(true);

  const hiddenPrice = getPrice(listing) === 'Request a Price';
  const price = get(listing, 'price.type.amount.USD');
  const financeable = isFinanceable(price, listing.year, true);
  const isUSBDP = selectedLocale === 'en-US';

  return !hideTridentFeatures && !isOemModel && !hiddenPrice && financeable && isUSBDP;
};
