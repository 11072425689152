import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {withCookies, Cookies} from 'react-cookie';
import {PortalConfigContext} from '../../config/portal';
import {
  getCurrentLocale, getTranslatedLocationsSync,
} from '../../utils/language';
import * as utils from '../../store/utils';
import {isTypeCookieAllowed} from '../../utils/cookies';
import {KAMELEOON_VISITOR_CODE, PERMUTIVE_ID} from '../../constants/cookies';
import {hrefLangs, htmlLanguage} from '../../tppServices/translations/languageConstants';
import { getUiLibThemeStyles, UI_THEME } from '../../tppServices/uiLibThemes';

/*
To debug links during development we can follow this approach:
Create a state variable and update it with useEffect to
async load all translations and translate urls

  // Useful to debug and develop. This will download all languages and translate all routes client side.
  // You can uncomment on development
  const [localizedLocation, setLocalizedLocation] = useState(translatedLocs);
  useEffect(() => {
    const {tpp} = useTPPServices();
    const translateAllLocations = async () => {
      let translatedUrls = await geti18nLocations(tpp, window.location.pathname, true);
      translatedUrls = translatedUrls.map((loc) => ({
        ...loc,
        route: !loc.route.endsWith('/') ? loc.route + '/' : loc.route
      }));
      setLocalizedLocation(translatedUrls);
    };
    callOnDev(translateAllLocations);
  }, [tpp]);
*/

const SiteMeta = (props) => {
  const context = useContext(PortalConfigContext);
  const localizedLocation = getTranslatedLocationsSync(props.location);
  const cdnUrl = get(context, 'client.cdn.endpoint');
  const addPermutive = get(context, 'supports.permutive');
  const isPermutiveAllowed = isTypeCookieAllowed(
    PERMUTIVE_ID,
    props.cookies.cookies
  );
  const language = getCurrentLocale();
  const gtmContainer = get(context, 'scripts.gtm.container');
  const gtmParams = get(context, 'scripts.gtm.params', '');
  const digitalDataBuilder = context?.scripts?.digitalDataBuilder;
  const abVariantCookies = get(context, 'supports.abVariantCookies', false);
  const addKameleoon = get(context, 'supports.kameleoon', false);
  const DEFAULT_LANGUAGE_CODE = context.language;
  const cmpScripts = get(context, 'scripts.cmp', '');
  const supportsCmp = get(context, 'supports.cmp', false);
  const isKameleoonAllowed = isTypeCookieAllowed(
    KAMELEOON_VISITOR_CODE,
    props.cookies.cookies
  );
  const serveFontsFromCloudFront = get(context, 'supports.serveFontsFromCloudFront', false);

  return (
    <Helmet>
      <html lang={htmlLanguage[language] || language} />
      <meta charset="utf-8" />
      <link rel="shortcut icon" href={`${cdnUrl}/assets/favicon.ico`} />
      {serveFontsFromCloudFront && <link href={`${cdnUrl}/assets/css/roboto.css`} rel="stylesheet"/>}
      {serveFontsFromCloudFront && <link href={`${cdnUrl}/assets/css/ibarrarealnova.css`} rel="stylesheet"/>}
      {!serveFontsFromCloudFront && <link rel="preconnect" href="https://fonts.googleapis.com"/>}
      {!serveFontsFromCloudFront && <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>}
      {!serveFontsFromCloudFront && <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"/>}
      {!serveFontsFromCloudFront && <link href="https://fonts.googleapis.com/css2?family=Ibarra%20Real%20Nova:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"/>}
      <link
        rel="stylesheet"
        type="text/css"
        href={`${cdnUrl}/assets/css/portal.css`}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />
      {/*
    manifest.json provides metadata used when your web app is added to the
    homescreen on Android. See https://developers.google.com/web/fundamentals/web-app-manifest/
    */}
      <link rel="manifest" href={`${cdnUrl}/assets/manifest.webmanifest`} />
      <style id={UI_THEME}>{getUiLibThemeStyles(context.name)}</style>
      <script>{`
        var dataLayer = window.dataLayer || [];
        var gaExpIds = [];

        var cookies = Object.fromEntries(document.cookie.split('; ').map(x => x.split('=')));
        var absortCookie = cookies['ab-sort'];
        if (absortCookie) {
          const abJson = JSON.parse(decodeURIComponent(absortCookie));
          gaExpIds.push(abJson.experimentId + '.' + abJson.variant);
        }

        var variantCookies = Object.keys(cookies).filter((c) => c.startsWith('ab-'));
        if (variantCookies && ${abVariantCookies}) {
          for (let i = 0; i < variantCookies.length; i++) {
            const cookie = variantCookies[i];
            gaExpIds.push(cookie.slice(3) + '.' + cookies[cookie]);
          }
        }

        if (gaExpIds.length) {
          dataLayer.push({
            'gaExpId': gaExpIds.join('!')
          });
        }

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmParams}';
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmContainer}');
    `}</script>
      {utils.isServer() && (
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainer}${gtmParams}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      )}
      {utils.isServer() && supportsCmp &&
        cmpScripts.map((cmpScript, index) => (
          <script
            id={`cmp-script-${index + 1}`}
            key={`cmp-script-${index + 1}`}
            type="text/javascript"
            defer
            src={cmpScript}
          />
        ))}
      <script
        id="digitalDataBuilder"
        type="text/javascript"
        src={digitalDataBuilder}
      />
      {localizedLocation &&
        localizedLocation.map((lang, key) => {
          let langTag =
            lang.lang === DEFAULT_LANGUAGE_CODE
              ? 'x-default'
              : hrefLangs[lang.lang];
          return (
            <link
              key={key}
              rel="alternate"
              hrefLang={langTag}
              href={lang.route}
              id={`the-localizedlocations-${key}`}
            />
          );
        })}

      {addPermutive && isPermutiveAllowed && (
        <script id="permutive-script-1">
          {
            // eslint-disable-next-line max-len
            '!function (e, o, n, i) { if (!e) { e = e || {}, window.permutive = e, e.q = []; var t = function () { return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (e) { return (e ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> e / 4).toString(16) }) }; e.config = i || {}, e.config.apiKey = o, e.config.workspaceId = n, e.config.environment = e.config.environment || "production", (window.crypto || window.msCrypto) && (e.config.viewId = t()); for (var g = ["addon", "identify", "track", "trigger", "query", "segment", "segments", "ready", "on", "once", "user", "consent"], r = 0; r < g.length; r++) { var w = g[r]; e[w] = function (o) { return function () { var n = Array.prototype.slice.call(arguments, 0); e.q.push({ functionName: o, arguments: n }) } }(w) } } }(window.permutive, "c112520e-b2cd-45d2-b768-55e17cf56c28", "8217d3e6-98f8-4c16-8720-982d219aa6b3", { "consentRequired": true });'
          }
        </script>
      )}
      {addPermutive && isPermutiveAllowed && (
        <script
          id="permutive-script-2"
          async
          src="https://8217d3e6-98f8-4c16-8720-982d219aa6b3.edge.permutive.app/8217d3e6-98f8-4c16-8720-982d219aa6b3-web.js"
        />
      )}
      {addPermutive && isPermutiveAllowed && (
        <script id="permutive-init">{'window.permutiveHelper.init();'}</script>
      )}
      {addKameleoon && isKameleoonAllowed && (
        <script id="kameleoon-helper">window.kameleoonHelper.init();</script>
      )}
    </Helmet>
  );
};

SiteMeta.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  cookies: PropTypes.instanceOf(Cookies).isRequired
};

export default withCookies(withRouter(SiteMeta));
